export default {
    login: "/openApi/login", //登录
    updatePwd: "/openApi/integrated/updatePwd",// 修改密码
    updateUserFirst: "/openApi/integrated/updateUserFirst",//修改登录状态为 非第一次登录
    updateFirstPwd: "/openApi/integrated/updateFirstPwd",// 初次登录修改密码
    sms: "/openApi/sms",//发送验证码
    forceLogout: "/openApi/forceLogout", //退出登录
    getDepartmentList: "/openApi/integrated/getDepartmentList", //查询科室列表 分页
    getDepartment: "/openApi/integrated/getDepartment", //获取单个科室信息
    getDepartmentUserList: "/openApi/integrated/getDepartmentUserList", //获取科室用户列表 分页
    addAndEdit: "/openApi/integrated/addAndEdit",// 添加或修改科室用户
    addDepartment: "/openApi/integrated/addDepartment",// 添加或修改科室信息
    removeDepartmentUser: "/openApi/integrated/removeDepartmentUser",// 删除科室用户 
    removeDepartment: "/openApi/integrated/removeDepartment",// 删除科室
    getDepartmentYearList: "/openApi/departmentYear/getDepartmentYearList",// 查询科室年份列表 分页
    getDepartmentSalaryList: "/openApi/departmentYear/getDepartmentSalaryList",// 科室薪酬数据列表 分页
    getDepartmentSalaryDetails: "/openApi/departmentYear/getDepartmentSalaryDetails",// 科室薪资月份列表
    updateDepartmentSalaryDetails: "/openApi/departmentYear/updateDepartmentSalaryDetails",// 修改科室薪资月份表
    departmentList: "/openApi/integrated/hospitalBase/departmentList",// 科室数据更新时间列表
    listhosInward: "/openApi/hosInpatientWard/list",//病区列表（带分页）
    addInward: "/openApi/hosInpatientWard/add",//新增病区信息
    editTnward: "/openApi/hosInpatientWard/update",//编辑修改病区信息
    deleteTnward: "/openApi/hosInpatientWard/delete",//删除病区信息
    findByhosInward: "/openApi/hosInpatientWard/findBy",//病区列表（无分页）
    findBySys: "/system/dict/data/findBy",//根据字典类型查询字典数据中的信息
    addhosDepartmentUser: "/openApi/hosDepartmentUser/add",//新增科室人员信息
    listhosDepartmentUser: "/openApi/hosDepartmentUser/list",//科室人员信息列表(带分页)
    updateDepartmentUser: "/openApi/hosDepartmentUser/update",//编辑修改科室人员信息
    addworkIn: "/openApi/hosWorkloadIndicators/add",//新增工作量指标
    listworkIn: "/openApi/hosWorkloadIndicators/list",//工作量指标管理列表（分页）
    updateworkIn: "/openApi/hosWorkloadIndicators/update",//编辑修改工作量指标
    deleteworkIn: "/openApi/hosWorkloadIndicators/delete",//删除工作量指标
    rewardList: "/openApi/hosSpecialRewardIndicators/list",//专项奖励指标管理列表（分页）
    rewardAdd: "/openApi/hosSpecialRewardIndicators/add",//新增专项奖励指标
    rewardUpdate: "/openApi/hosSpecialRewardIndicators/update",//编辑修改专项奖励指标
    rewardDelete: "/openApi/hosSpecialRewardIndicators/delete",//删除专项奖励指标
    vidbsiList: "/openApi/hosIndividualSubsidyIndicators/list",//单项补助奖励指标管理分页列表
    vidbsiAdd: "/openApi/hosIndividualSubsidyIndicators/add",//新增单项补助奖励指标
    vidbsiUpdate: "/openApi/hosIndividualSubsidyIndicators/update",//编辑修改单项补助奖励指标
    vidbsiDelete: "/openApi/hosIndividualSubsidyIndicators/delete",//删除单项补助奖励指标
    perforList: "/openApi/hosPersonalPerformanceIndicators/list",//个人绩效考核指标管理列表 
    perforAdd: "/openApi/hosPersonalPerformanceIndicators/add",//新增个人绩效考核指标
    perforUpdate: "/openApi/hosPersonalPerformanceIndicators/update",//编辑修改个人绩效考核指标
    perforDelete: "/openApi/hosPersonalPerformanceIndicators/delete",//删除个人绩效考核指标
    scresList: "/openApi/hosScientificResearchIndicators/list",//科研绩效指标管理列表 （分页）
    scresAdd: "/openApi/hosScientificResearchIndicators/add",//新增科研绩效指标
    scresUpdate: "/openApi/hosScientificResearchIndicators/update",//编辑修改科研绩效指标
    scresDelete: "/openApi/hosScientificResearchIndicators/delete",//删除科研绩效指标
    hosList: "/openApi/hosSpecialRewardFillings/list",//专项奖励填报分页列表
    hosUpload: "/openApi/sysUploadFiles/upload",//文件上传
    hosAdd: "/openApi/hosSpecialRewardFillings/add",//新增专项奖励填报
    hosfindBy: "/openApi/hosDepartmentUser/findBy",//科室人员信息列表（无分页）
    sysgetFile: "/openApi/sysUploadFiles/getFile",//获取文件信息
    hosupdate: "/openApi/hosSpecialRewardFillings/update",//编辑/修改专项奖励填报
    hosdelete: "/openApi/hosSpecialRewardFillings/delete",//删除专项奖励填报
    subadd: "/openApi/hosIndividualSubsidyFillings/add",//新增单项补助及奖惩填报信息
    sublist: "/openApi/hosIndividualSubsidyFillings/list",//单项补助及奖惩填报分页列表
    subupdate: "/openApi/hosIndividualSubsidyFillings/update",//编辑/修改单项补助及奖惩填报
    subdelete: "/openApi/hosIndividualSubsidyFillings/delete",//删除单项补助及奖惩填报数据
    hospeAdd: "/openApi/hosPersonalPerformanceFillings/add",//新增个人绩效考核指标填报
    hospeList: "/openApi/hosPersonalPerformanceFillings/list",//个人绩效考核指标填报分页列表
    hospeUpdate: "/openApi/hosPersonalPerformanceFillings/update",//编辑/修改个人绩效考核指标填报
    hospeDel: "/openApi/hosPersonalPerformanceFillings/delete",//删除个人绩效考核指标填报数据
    resList: "/openApi/hosScientificResearchFillings/list",//科研绩效指标填报分页列表
    resAdd: "/openApi/hosScientificResearchFillings/add",//新增科研绩效指标填报
    resUpdate: "/openApi/hosScientificResearchFillings/update",//编辑/修改科研绩效指标填报
    resDel: "/openApi/hosScientificResearchFillings/delete",//删除科研绩效指标填报数据
    worAdd: "/openApi/hosWorkloadFillings/add",//新增工作量指标填报
    worList: "/openApi/hosWorkloadFillings/list",//工作量指标填报分页列表
    worUpdate: "/openApi/hosWorkloadFillings/update",//编辑/修改工作量指标填报
    woeDel: "/openApi/hosWorkloadFillings/delete",//删除工作量指标填报数据
    hosFind: "/openApi/hosIndividualSubsidyIndicators/findBy",//工作量指标管理列表 (无分页)
    hosSpFind: "/openApi/hosSpecialRewardIndicators/findBy",//专项奖励指标管理列表 (无分页)
    hosIndFind: "/openApi/hosIndividualSubsidyIndicators/findBy",//单项补助奖励指标管理列表 (无分页)
    hosperFind: "/openApi/hosPersonalPerformanceIndicators/findBy",//个人绩效考核指标管理列表 (无分页) 
    hosScFind: "/openApi/hosScientificResearchIndicators/findBy",//科研绩效指标管理列表 (无分页)
    addhosdpl: "/openApi/hosDisciplines/add",//新增学科
    listhosdpl: "/openApi/hosDisciplines/list",//学科管理列表
    updatehosdpl: "/openApi/hosDisciplines/update",//编辑修改学科
    delehosdpl: "/openApi/hosDisciplines/delete",//删除学科
    hospostlistAdd: "/openApi/hosPostResponsibilitys/add",//新增岗位职责
    hospostList: "/openApi/hosPostResponsibilitys/list",//岗位职责列表 (带分页)
    hospostDelete: "/openApi/hosPostResponsibilitys/delete",//删除岗位职责
    hospostUpdate: "/openApi/hosPostResponsibilitys/update",//修改岗位职责
    hosdepAdd: "/openApi/hosDepartmentBasicInformations/add",//新增科室基础信息
    hosdepUpdate: "/openApi/hosDepartmentBasicInformations/update",//编辑修改科室基础信息
    hoddepFind: "/openApi/hosDepartmentBasicInformations/findByKey",//获取科室基础信息
    hoddepList: "/openApi/hosDepartmentBasicInformations/list",//科室基础信息分页列表
    findDetail: "/openApi/hosDepartmentBasicInformations/findDetail",//通过科室id获取科室基础信息
    hosPostRe: "/openApi/hosPostResponsibilitys/findBy",//岗位职责列表 (无分页)
    hosDisciplines: "/openApi/hosDisciplines/findBy",//学科管理列表 (无分页)
    findDepartment: "/openApi/integrated/findDepartment",//根据医院id查询科室列表 (无分页)
    deleteDepartment: "/openApi/hosDepartmentUser/delete",//删除科室人员信息
    updaDepartment: "/openApi/hosDepartmentUser/update",//编辑修改科室人员信息
    hosfindByKey: "/openApi/hosDepartmentUser/findByKey",//获取科室人员详细信息
    judgeHosDiscipline: "/openApi/hosDisciplines/judgeHosDiscipline",//判断学科是否已存在
    judgeInpatientWard: "/openApi/hosInpatientWard/judgeInpatientWard",//判断科室下的病区是否已存在
    judgeHosDepartmentUser: "/openApi/hosDepartmentUser/judgeHosDepartmentUser",//判断科室人员的工号是否已存在
    judgeDepartment: "/openApi/integrated/judgeDepartment",//判断科室是否已存在
    peradd: "/openApi/performanceIndicatorLibrarys/add",//新增绩效指标
    perlist: "/openApi/performanceIndicatorLibrarys/list",// 绩效指标库分页列表
    perupdate: "/openApi/performanceIndicatorLibrarys/update",//编辑修改绩效指标
    perdele: "/openApi/performanceIndicatorLibrarys/delete",//删除指标
    opeadd: "/openApi/performanceManagements/add",//新增 科室绩效指标关联关系
    openlist: "/openApi/performanceManagements/list",//科室绩效指标关联关系 分页列表
    perfindDetail: "/openApi/performanceManagements/findDetail",//查看科室绩效指标关联关系详情
    scheAdd: "/openApi/performanceAllocationSchemes/add",//新增科室绩效分配方案
    scheList: "/openApi/performanceAllocationSchemes/list",//科室绩效分配方案分页列表
    optionselect: "/system/dict/type/optionselect",//获取字典选择框列表
    perjudge: "/openApi/performanceManagements/judge",//判断该科室下是否已存在绩效指标管理数据
    perupad: "/openApi/performanceManagements/update",//编辑修改 科室绩效指标关联关系
    editfindBy: "/openApi/performanceIndicatorLibrarys/findByKey",//查看指标详情
    checkName: "/openApi/performanceIndicatorLibrarys/checkName",//新增指标时 判断该指标名称是否已存在该指标类型下
    sysDictType: "/openApi/performanceIndicatorLibrarys/sysDictType",//获取科室绩效数据字典分类信息
    sysDictData: "/openApi/performanceIndicatorLibrarys/sysDictData",//获取科室绩效数据字典详情信息
    seconadd: "/openApi/performanceIndicatorSecondLibrarys/add",//新增绩效指标库管理 二级指标
    secondList: "/openApi/performanceIndicatorSecondLibrarys/list",//绩效指标库管理 二级指标分页列表
    thirdList: "/openApi/performanceIndicatorThirdLibrarys/list",//绩效指标库管理 三级指标分页列表
    thirdAdd: "/openApi/performanceIndicatorThirdLibrarys/add",//新增
    thirdUpdate: "/openApi/performanceIndicatorSecondLibrarys/update",//二级编辑修改
    secondDele: "/openApi/performanceIndicatorSecondLibrarys/delete",//二级删除
    threUpdate: "/openApi/performanceIndicatorThirdLibrarys/update",//三级编辑修改
    thirDel: "/openApi/performanceIndicatorThirdLibrarys/delete",//三级删除
    perdindBy: "/openApi/performanceIndicatorSecondLibrarys/findBy",//绩效指标库管理 二级指标列表 (无分页)
    perlibDetail: "/openApi/performanceIndicatorSecondLibrarys/findDetail",//获取指标详情以及下属的三级指标
    findByType: "/openApi/performanceManagements/findByType",//回显全部类型指标
    echoalltype: "/openApi/performanceAllocationSchemes/findByType",//回显该方案下所有指标配置的分值
    allocUpdate: "/openApi/performanceAllocationSchemes/update",//编辑修改科室绩效分配方案
    fillList: "/openApi/performanceFillIns/list",//科室绩效数据填报列表（分页）
    fillAdd: "/openApi/performanceFillIns/add",//新增月度填报
    perfillList: "/openApi/performanceFillInUsers/list",//科室填报数据列表（分页）
    perfillUpdaStatus: "/openApi/performanceFillInUsers/updateAuditStatus",//修改审核状态
    perallSchFind: "/openApi/performanceAllocationSchemes/findBy",//科室绩效分配方案(无分页)
    findByOtherType: "/openApi/performanceFillInUsers/findByOtherType",//回显绩效方案下配置的指标信息
    addBatch: "/openApi/performanceFillInUserOthers/addBatch",//批量新增其他个人指标填报
    findFillInData: "/openApi/performanceFillInUserOthers/findFillInData",//回显其他个人指标填报的数据
    addOtherUser: "/openApi/performanceFillInUsers/addOtherUser",//添加外部科室人员
    copyScheme: "/openApi/performanceAllocationSchemes/copyScheme",//复制方案
    checkFillInUsers: "/openApi/performanceFillInUsers/checkFillInUsers",//检查该科室下是否有新增的医务人员
    getBasicLibrary: "/openApi/performanceFillInUsers/getBasicLibrary",//查看个人基本指标填报信息
    getRouters: "/openApi/getRouters",//菜单权限
    findWorkload: "/openApi/performanceFillInUsers/findWorkload",//回显绩效方案下配置的工作量指标
    addWorkload: "/openApi/performanceFillInUserOthers/addWorkload",//工作量指标填报
    findWorkloadFillInData: "/openApi/performanceFillInUserOthers/findWorkloadFillInData",//回显工作量指标填报数据
    gaoYaYangCalculation: "/openApi/perCalculations/gaoYaYangCalculation",//合肥二院高压氧科绩效测算
    erLongLuCalculation: "/openApi/perCalculations/erLongLuCalculation",//二龙路绩效测算
    gaoYaYangList: "/openApi/perCalculations/list",//科室绩效测算分页列表
    gaoYaYangDetailList: "/openApi/perCalculationDetails/list",//查看科室个人绩效测算详情（分页）
    findPerformanceCalculationSum: "/openApi/perCalculationDetails/findPerformanceCalculationSum",//科室测算后的绩效总额
    gaoYaYangRecalculate: "/openApi/perCalculations/gaoYaYangRecalculate",//合肥二院高压氧科绩效测算-重新测算
    erLongLuRecalculate: "/openApi/perCalculations/erLongLuRecalculate",//二龙路科绩效测算-重新测算
    importExcel: "/openApi/perCalculationDetails/importExcel",//导出绩效测算 计算结果
    findCalculationUserDetail: "/openApi/perCalculationDetails/findCalculationUserDetail",//个人绩效总额详情页
    exportExcel: "/openApi/hosDepartmentUser/exportExcel",//导出人员信息
    importExcPer: "/openApi/hosDepartmentUser/importExcel",//导入人员信息
    perexportExcel: "/openApi/performanceFillInUsers/exportExcel",//导出填报数据详情
    findDifference: "/openApi/perCalculations/findDifference",//科室绩效测算后的差额
    percfindByKey: "/openApi/perCalculations/findByKey",//科室绩效的应发
    perDelete: "/openApi/perCalculations/delete",//删除科室绩效测算















































};

