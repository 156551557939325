// 模块接口文件，暴漏给外部模块使用

// 导入核心模块
import { request } from "./core";
// 导入请求方式
import METHODS from "./methods";
// 导入请求接口
import URL from "./url";

const API = {
    // 登录接口
    login(data) {
        return request(METHODS.POST, URL.login, data);
    },
    // 修改密码
    updatePwd(data) {
        return request(METHODS.POST, URL.updatePwd, data);
    },
    // 修改登录状态为 非第一次登录
    updateUserFirst(data) {
        return request(METHODS.POST, URL.updateUserFirst, data);
    },
    // 初次登录修改密码
    updateFirstPwd(data) {
        return request(METHODS.POST, URL.updateFirstPwd, data);
    },
    // 发送验证码
    sms(data) {
        return request(METHODS.POST, URL.sms, data);
    },
    // 退出登录
    forceLogout(data) {
        return request(METHODS.POST, URL.forceLogout, data);
    },
    // 查询科室列表 分页
    getDepartmentList(data) {
        return request(METHODS.GET, URL.getDepartmentList, { params: data });
    },
    //获取单个科室信息
    getDepartment(data) {
        return request(METHODS.GET, URL.getDepartment, { params: data });
    },
    // 获取科室用户列表 分页
    getDepartmentUserList(data) {
        return request(METHODS.GET, URL.getDepartmentUserList, { params: data });
    },
    // 添加或修改科室用户
    addAndEdit(data) {
        return request(METHODS.POST, URL.addAndEdit, data);
    },
    // 添加或修改科室信息
    addDepartment(data) {
        return request(METHODS.POST, URL.addDepartment, data);
    },
    // 删除科室用户
    removeDepartmentUser(data) {
        return request(METHODS.POST, URL.removeDepartmentUser, data);
    },
    // 删除科室
    removeDepartment(data) {
        return request(METHODS.POST, URL.removeDepartment, data);
    },
    // 查询科室年份列表 分页
    getDepartmentYearList(data) {
        return request(METHODS.GET, URL.getDepartmentYearList, { params: data });
    },
    // 科室薪酬数据列表 分页
    getDepartmentSalaryList(data) {
        return request(METHODS.GET, URL.getDepartmentSalaryList, { params: data });
    },
    // 科室薪资月份列表
    getDepartmentSalaryDetails(data) {
        return request(METHODS.GET, URL.getDepartmentSalaryDetails, { params: data });
    },
    // 修改科室薪资月份表
    updateDepartmentSalaryDetails(data) {
        return request(METHODS.POST, URL.updateDepartmentSalaryDetails, data);
    },
    // 科室数据更新时间列表
    departmentList(data) {
        return request(METHODS.GET, URL.departmentList, { params: data });
    },
    // 病区列表（带分页）
    listhosInward(data) {
        return request(METHODS.GET, URL.listhosInward, { params: data });
    },
    // 新增病区信息
    addInward(data) {
        return request(METHODS.POST, URL.addInward, data);
    },
    // 编辑修改病区信息
    editTnward(data) {
        return request(METHODS.POST, URL.editTnward, data);
    },
    // 删除病区信息
    deleteTnward(data) {
        return request(METHODS.POST, URL.deleteTnward, data);
    },
    // 病区列表（无分页）
    findByhosInward(data) {
        return request(METHODS.GET, URL.findByhosInward, { params: data });
    },
    // 根据字典类型查询字典数据中的信息
    findBySys(data) {
        return request(METHODS.GET, URL.findBySys, { params: data });
    },
    // 新增科室人员信息
    addhosDepartmentUser(data) {
        return request(METHODS.POST, URL.addhosDepartmentUser, data);
    },
    // 科室人员信息列表(带分页)
    listhosDepartmentUser(data) {
        return request(METHODS.GET, URL.listhosDepartmentUser, { params: data });
    },
    // 编辑修改科室人员信息
    updateDepartmentUser(data) {
        return request(METHODS.POST, URL.updateDepartmentUser, data);
    },
    // 新增工作量指标
    addworkIn(data) {
        return request(METHODS.POST, URL.addworkIn, data);
    },
    // 工作量指标管理列表（分页）
    listworkIn(data) {
        return request(METHODS.GET, URL.listworkIn, { params: data });
    },
    // 编辑修改工作量指标
    updateworkIn(data) {
        return request(METHODS.POST, URL.updateworkIn, data);
    },
    // 删除工作量指标
    deleteworkIn(data) {
        return request(METHODS.POST, URL.deleteworkIn, data);
    },
    // 专项奖励指标管理列表（分页）
    rewardList(data) {
        return request(METHODS.GET, URL.rewardList, { params: data });
    },
    // 新增专项奖励指标
    rewardAdd(data) {
        return request(METHODS.POST, URL.rewardAdd, data);
    },
    // 编辑修改专项奖励指标
    rewardUpdate(data) {
        return request(METHODS.POST, URL.rewardUpdate, data);
    },
    // 删除专项奖励指标
    rewardDelete(data) {
        return request(METHODS.POST, URL.rewardDelete, data);
    },
    // 单项补助奖励指标管理分页列表
    vidbsiList(data) {
        return request(METHODS.GET, URL.vidbsiList, { params: data });
    },
    // 新增单项补助奖励指标
    vidbsiAdd(data) {
        return request(METHODS.POST, URL.vidbsiAdd, data);
    },
    // 编辑修改单项补助奖励指标
    vidbsiUpdate(data) {
        return request(METHODS.POST, URL.vidbsiUpdate, data);
    },
    // 删除单项补助奖励指标
    vidbsiDelete(data) {
        return request(METHODS.POST, URL.vidbsiDelete, data);
    },
    // 个人绩效考核指标管理列表 
    perforList(data) {
        return request(METHODS.GET, URL.perforList, { params: data });
    },
    // 新增个人绩效考核指标
    perforAdd(data) {
        return request(METHODS.POST, URL.perforAdd, data);
    },
    // 编辑修改个人绩效考核指标
    perforUpdate(data) {
        return request(METHODS.POST, URL.perforUpdate, data);
    },
    // 删除个人绩效考核指标
    perforDelete(data) {
        return request(METHODS.POST, URL.perforDelete, data);
    },
    //科研绩效指标管理列表 （分页）
    scresList(data) {
        return request(METHODS.GET, URL.scresList, { params: data });
    },
    // 新增科研绩效指标
    scresAdd(data) {
        return request(METHODS.POST, URL.scresAdd, data);
    },
    // 编辑修改科研绩效指标
    scresUpdate(data) {
        return request(METHODS.POST, URL.scresUpdate, data);
    },
    // 删除科研绩效指标
    scresDelete(data) {
        return request(METHODS.POST, URL.scresDelete, data);
    },
    // 专项奖励填报分页列表
    hosList(data) {
        return request(METHODS.GET, URL.hosList, { params: data });
    },
    // 文件上传
    hosUpload(data) {
        return request(METHODS.POST, URL.hosUpload, data);
    },
    // 新增专项奖励填报
    hosAdd(data) {
        return request(METHODS.POST, URL.hosAdd, data);
    },
    //科室人员信息列表（无分页）
    hosfindBy(data) {
        return request(METHODS.GET, URL.hosfindBy, { params: data });
    },
    // 查看图片
    sysgetFile(data) {
        return request(METHODS.POST, URL.sysgetFile, data);
    },
    // 编辑/修改专项奖励填报
    hosupdate(data) {
        return request(METHODS.POST, URL.hosupdate, data);
    },
    // 删除专项奖励填报
    hosdelete(data) {
        return request(METHODS.POST, URL.hosdelete, data);
    },
    // 新增单项补助及奖惩填报信息
    subadd(data) {
        return request(METHODS.POST, URL.subadd, data);
    },
    // 单项补助及奖惩填报分页列表
    sublist(data) {
        return request(METHODS.GET, URL.sublist, { params: data });
    },
    //编辑/修改单项补助及奖惩填报
    subupdate(data) {
        return request(METHODS.POST, URL.subupdate, data);
    },
    // 删除单项补助及奖惩填报数据
    subdelete(data) {
        return request(METHODS.POST, URL.subdelete, data);
    },
    // 新增个人绩效考核指标填报
    hospeAdd(data) {
        return request(METHODS.POST, URL.hospeAdd, data);
    },
    // 个人绩效考核指标填报分页列表
    hospeList(data) {
        return request(METHODS.GET, URL.hospeList, { params: data });
    },
    // 编辑/修改个人绩效考核指标填报
    hospeUpdate(data) {
        return request(METHODS.POST, URL.hospeUpdate, data);
    },
    // 删除个人绩效考核指标填报数据
    hospeDel(data) {
        return request(METHODS.POST, URL.hospeDel, data);
    },
    // 科研绩效指标填报分页列表
    resList(data) {
        return request(METHODS.GET, URL.resList, { params: data });
    },
    // 新增科研绩效指标填报
    resAdd(data) {
        return request(METHODS.POST, URL.resAdd, data);
    },
    // 编辑/修改科研绩效指标填报
    resUpdate(data) {
        return request(METHODS.POST, URL.resUpdate, data);
    },
    // 删除科研绩效指标填报数据
    resDel(data) {
        return request(METHODS.POST, URL.resDel, data);
    },
    // 新增工作量指标填报
    worAdd(data) {
        return request(METHODS.POST, URL.worAdd, data);
    },
    // 工作量指标填报分页列表
    worList(data) {
        return request(METHODS.GET, URL.worList, { params: data });
    },
    // 编辑/修改工作量指标填报
    worUpdate(data) {
        return request(METHODS.POST, URL.worUpdate, data);
    },
    // 删除工作量指标填报数据
    woeDel(data) {
        return request(METHODS.POST, URL.woeDel, data);
    },
    // 工作量指标管理列表 (无分页)
    hosFind(data) {
        return request(METHODS.GET, URL.hosFind, { params: data });
    },
    // 专项奖励指标管理列表 (无分页)
    hosSpFind(data) {
        return request(METHODS.GET, URL.hosSpFind, { params: data });
    },
    // 单项补助奖励指标管理列表 (无分页)
    hosIndFind(data) {
        return request(METHODS.GET, URL.hosIndFind, { params: data });
    },
    //个人绩效考核指标管理列表 (无分页) 
    hosperFind(data) {
        return request(METHODS.GET, URL.hosperFind, { params: data });
    },
    //科研绩效指标管理列表 (无分页)
    hosScFind(data) {
        return request(METHODS.GET, URL.hosScFind, { params: data });
    },
    // 新增学科
    addhosdpl(data) {
        return request(METHODS.POST, URL.addhosdpl, data);
    },
    // 学科管理列表
    listhosdpl(data) {
        return request(METHODS.GET, URL.listhosdpl, { params: data });
    },
    // 编辑修改学科
    updatehosdpl(data) {
        return request(METHODS.POST, URL.updatehosdpl, data);
    },
    // 删除学科
    delehosdpl(data) {
        return request(METHODS.POST, URL.delehosdpl, data);
    },
    // 新增岗位职责
    hospostlistAdd(data) {
        return request(METHODS.POST, URL.hospostlistAdd, data);
    },
    //岗位职责列表 (带分页)
    hospostList(data) {
        return request(METHODS.GET, URL.hospostList, { params: data });
    },
    // 删除岗位职责
    hospostDelete(data) {
        return request(METHODS.POST, URL.hospostDelete, data);
    },
    // 修改岗位职责
    hospostUpdate(data) {
        return request(METHODS.POST, URL.hospostUpdate, data);
    },
    // 新增科室基础信息
    hosdepAdd(data) {
        return request(METHODS.POST, URL.hosdepAdd, data);
    },
    // 编辑修改科室基础信息
    hosdepUpdate(data) {
        return request(METHODS.POST, URL.hosdepUpdate, data);
    },
    // 通过科室id获取科室基础信息
    findDetail(data) {
        return request(METHODS.GET, URL.findDetail, { params: data });
    },
    // 获取科室基础信息
    hoddepFind(data) {
        return request(METHODS.GET, URL.hoddepFind, { params: data });
    },
    // 科室基础信息分页列表
    hoddepList(data) {
        return request(METHODS.GET, URL.hoddepList, { params: data });
    },
    //岗位职责列表 (无分页)
    hosPostRe(data) {
        return request(METHODS.GET, URL.hosPostRe, { params: data });
    },
    //学科管理列表 (无分页)
    hosDisciplines(data) {
        return request(METHODS.GET, URL.hosDisciplines, { params: data });
    },
    //根据医院id查询科室列表 (无分页)
    findDepartment(data) {
        return request(METHODS.GET, URL.findDepartment, { params: data });
    },
    // 删除科室人员信息
    deleteDepartment(data) {
        return request(METHODS.POST, URL.deleteDepartment, data);
    },
    // 编辑修改科室人员信息
    updaDepartment(data) {
        return request(METHODS.POST, URL.updaDepartment, data);
    },
    //获取科室人员详细信息
    hosfindByKey(data) {
        return request(METHODS.GET, URL.hosfindByKey, { params: data });
    },
    // 判断学科是否已存在
    judgeHosDiscipline(data) {
        return request(METHODS.POST, URL.judgeHosDiscipline, data);
    },
    // 判断科室下的病区是否已存在
    judgeInpatientWard(data) {
        return request(METHODS.POST, URL.judgeInpatientWard, data);
    },
    // 判断科室人员的工号是否已存在
    judgeHosDepartmentUser(data) {
        return request(METHODS.POST, URL.judgeHosDepartmentUser, data);
    },
    // 判断科室是否已存在
    judgeDepartment(data) {
        return request(METHODS.POST, URL.judgeDepartment, data);
    },
    // 新增绩效指标
    peradd(data) {
        return request(METHODS.POST, URL.peradd, data);
    },
    // 绩效指标库分页列表
    perlist(data) {
        return request(METHODS.GET, URL.perlist, { params: data });
    },
    // 编辑修改绩效指标
    perupdate(data) {
        return request(METHODS.POST, URL.perupdate, data);
    },
    // 删除指标
    perdele(data) {
        return request(METHODS.POST, URL.perdele, data);
    },
    //新增 科室绩效指标关联关系
    opeadd(data) {
        return request(METHODS.POST, URL.opeadd, data);
    },
    //科室绩效指标关联关系 分页列表
    openlist(data) {
        return request(METHODS.GET, URL.openlist, { params: data });
    },
    //查看科室绩效指标关联关系详情
    perfindDetail(data) {
        return request(METHODS.GET, URL.perfindDetail, { params: data });
    },
    // 新增科室绩效分配方案
    scheAdd(data) {
        return request(METHODS.POST, URL.scheAdd, data);
    },
    // 科室绩效分配方案分页列表
    scheList(data) {
        return request(METHODS.GET, URL.scheList, { params: data });
    },
    // 获取字典选择框列表
    optionselect(data) {
        return request(METHODS.GET, URL.optionselect, { params: data });
    },
    // 判断该科室下是否已存在绩效指标管理数据
    perjudge(data) {
        return request(METHODS.POST, URL.perjudge, data);
    },
    //编辑修改 科室绩效指标关联关系
    perupad(data) {
        return request(METHODS.POST, URL.perupad, data);
    },
    // 查看指标详情
    editfindBy(data) {
        return request(METHODS.GET, URL.editfindBy, { params: data });
    },
    //新增指标时 判断该指标名称是否已存在该指标类型下
    checkName(data) {
        return request(METHODS.POST, URL.checkName, data);
    },
    // 获取科室绩效数据字典分类信息
    sysDictType(data) {
        return request(METHODS.POST, URL.sysDictType, data);
    },
    // 获取科室绩效数据字典详情信息
    sysDictData(data) {
        return request(METHODS.POST, URL.sysDictData, data);
    },
    //新增绩效指标库管理 二级指标
    seconadd(data) {
        return request(METHODS.POST, URL.seconadd, data);
    },
    //绩效指标库管理 二级指标分页列表
    secondList(data) {
        return request(METHODS.GET, URL.secondList, { params: data });
    },
    //绩效指标库管理 三级指标分页列表
    thirdList(data) {
        return request(METHODS.GET, URL.thirdList, { params: data });
    },
    //新增
    thirdAdd(data) {
        return request(METHODS.POST, URL.thirdAdd, data);
    },
    // 编辑修改
    thirdUpdate(data) {
        return request(METHODS.POST, URL.thirdUpdate, data);
    },
    // 三级编辑修改
    threUpdate(data) {
        return request(METHODS.POST, URL.threUpdate, data);
    },
    // 二级删除
    secondDele(data) {
        return request(METHODS.POST, URL.secondDele, data);
    },
    // 三级删除
    thirDel(data) {
        return request(METHODS.POST, URL.thirDel, data);
    },
    //绩效指标库管理 二级指标列表 (无分页)
    perdindBy(data) {
        return request(METHODS.GET, URL.perdindBy, { params: data });
    },
    //获取指标详情以及下属的三级指标
    perlibDetail(data) {
        return request(METHODS.GET, URL.perlibDetail, { params: data });
    },
    // 回显全部类型指标
    findByType(data) {
        return request(METHODS.POST, URL.findByType, data);
    },
    // 回显该方案下所有指标配置的分值
    echoalltype(data) {
        return request(METHODS.POST, URL.echoalltype, data);
    },
    // 编辑修改科室绩效分配方案
    allocUpdate(data) {
        return request(METHODS.POST, URL.allocUpdate, data);
    },
    //科室绩效数据填报列表（分页）
    fillList(data) {
        return request(METHODS.GET, URL.fillList, { params: data });
    },
    // 新增月度填报
    fillAdd(data) {
        return request(METHODS.POST, URL.fillAdd, data);
    },
    //科室填报数据列表（分页）
    perfillList(data) {
        return request(METHODS.GET, URL.perfillList, { params: data });
    },
    // 修改审核状态
    perfillUpdaStatus(data) {
        return request(METHODS.POST, URL.perfillUpdaStatus, data);
    },
    //科室绩效分配方案(无分页)
    perallSchFind(data) {
        return request(METHODS.GET, URL.perallSchFind, { params: data });
    },
    // 回显绩效方案下配置的指标信息
    findByOtherType(data) {
        return request(METHODS.POST, URL.findByOtherType, data);
    },
    // 批量新增其他个人指标填报
    addBatch(data) {
        return request(METHODS.POST, URL.addBatch, data);
    },
    // 回显其他个人指标填报的数据
    findFillInData(data) {
        return request(METHODS.GET, URL.findFillInData, { params: data });
    },
    // 添加外部科室人员
    addOtherUser(data) {
        return request(METHODS.POST, URL.addOtherUser, data);
    },
    // 复制方案
    copyScheme(data) {
        return request(METHODS.POST, URL.copyScheme, data);
    },
    // 检查该科室下是否有新增的医务人员
    checkFillInUsers(data) {
        return request(METHODS.POST, URL.checkFillInUsers, data);
    },
    // 查看个人基本指标填报信息
    getBasicLibrary(data) {
        return request(METHODS.GET, URL.getBasicLibrary, { params: data });
    },
    // 菜单权限
    getRouters(data) {
        return request(METHODS.GET, URL.getRouters, { params: data });
    },
    // 回显绩效方案下配置的工作量指标
    findWorkload(data) {
        return request(METHODS.GET, URL.findWorkload, { params: data });
    },
    // 工作量指标填报
    addWorkload(data) {
        return request(METHODS.POST, URL.addWorkload, data);
    },
    // 回显工作量指标填报数据
    findWorkloadFillInData(data) {
        return request(METHODS.GET, URL.findWorkloadFillInData, { params: data });
    },
    // 合肥二院高压氧科绩效测算
    gaoYaYangCalculation(data) {
        return request(METHODS.POST, URL.gaoYaYangCalculation, data);
    },
    // 二龙路
    erLongLuCalculation(data) {
        return request(METHODS.POST, URL.erLongLuCalculation, data);
    },
    // 科室绩效测算分页列表
    gaoYaYangList(data) {
        return request(METHODS.GET, URL.gaoYaYangList, { params: data });
    },
    // 查看科室个人绩效测算详情（分页）
    gaoYaYangDetailList(data) {
        return request(METHODS.GET, URL.gaoYaYangDetailList, { params: data });
    },
    // 科室测算后的绩效总额
    findPerformanceCalculationSum(data) {
        return request(METHODS.GET, URL.findPerformanceCalculationSum, { params: data });
    },
    //合肥二院高压氧科绩效测算-重新测算
    gaoYaYangRecalculate(data) {
        return request(METHODS.POST, URL.gaoYaYangRecalculate, data);
    },
    erLongLuRecalculate(data) {
        return request(METHODS.POST, URL.erLongLuRecalculate, data);
    },
    //导出绩效测算 计算结果
    importExcel(data) {
        return request(METHODS.GET, URL.importExcel, { params: data, responseType: 'arraybuffer' });
    },
    // 个人绩效总额详情页
    findCalculationUserDetail(data) {
        return request(METHODS.GET, URL.findCalculationUserDetail, { params: data });
    },
    // 导出人员信息
    exportExcel(data) {
        return request(METHODS.GET, URL.exportExcel, { params: data, responseType: 'arraybuffer' });
    },
    // 导入人员信息
    importExcPer(data) {
        return request(METHODS.POST, URL.importExcPer, data);
    },
    // 导出填报数据详情
    perexportExcel(data) {
        return request(METHODS.GET, URL.perexportExcel, { params: data, responseType: 'arraybuffer' });
    },
    // 科室绩效测算后的差额
    findDifference(data) {
        return request(METHODS.POST, URL.findDifference, data);
    },
    // 科室绩效的应发
    percfindByKey(data) {
        return request(METHODS.GET, URL.percfindByKey, { params: data });
    },
    // 删除科室绩效测算
    perDelete(data) {
        return request(METHODS.POST, URL.perDelete, data);
    },
};

export default {
    // 将对象注入到Vue全局
    install(Vue) {
        Vue.prototype.$axios = API;
    }
};

